var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" ລູກຄ້າທີ່ໃຊ້ສ່ວນຫຼຸດ "),_c('span',{staticClass:"primary-color"},[_vm._v("("+_vm._s(_vm.promotions.length)+")")])])]),_c('v-col',{attrs:{"cols":""}},[_c('v-select',{attrs:{"required":"","items":_vm.useType,"item-text":"name","item-value":"id","label":"ປະເພດການໃຊ້ສ່ວນຫຼຸດ","outlined":"","dense":""},model:{value:(_vm.selectedUseType),callback:function ($$v) {_vm.selectedUseType=$$v},expression:"selectedUseType"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.promotions,"loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait","disable-pagination":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.volume))+" ")]}},{key:"item.buy_min_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.buy_min_price))+" ")]}},{key:"item.pro_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.promotionTypeText(item.pro_type))+" ")]}},{key:"item.pro_code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"info--text"},[_vm._v(" "+_vm._s(item.pro_code)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("DD-MM-YYYY"))+" ")])]}},{key:"item.use_for",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.useFor(item.use_for))+" ")])]}},{key:"item.expired_date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.moment(item.expired_date).format("DD-MM-YYYY"))+" ")])]}}])}),_c('br'),(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchData()}}}):_vm._e(),_c('ModalDelete',[[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDelete()}}},[_vm._v("Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK ")]),_c('v-spacer')],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }