<template>
  <div>
    <v-row>
      <v-col>
        <h3>
          ລູກຄ້າທີ່ໃຊ້ສ່ວນຫຼຸດ
          <span class="primary-color">({{ promotions.length }})</span>
        </h3>
      </v-col>
      <v-col cols>
        <v-select
            required
            v-model="selectedUseType"
            :items="useType"
            item-text="name"
            item-value="id"
            label="ປະເພດການໃຊ້ສ່ວນຫຼຸດ"
            outlined
            dense
        >
        </v-select>
      </v-col>
<!--      <v-col class="d-flex justify-end">-->
<!--        <template>-->
<!--          <div class="my-2">-->
<!--            <v-btn class="btn-primary" fab small dark @click="createPromotion()">-->
<!--              <v-icon>mdi-plus</v-icon>-->
<!--            </v-btn>-->
<!--          </div>-->
<!--        </template>-->
<!--      </v-col>-->
    </v-row>

    <v-data-table
        :headers="headers"
        :items="promotions"
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
        :disable-pagination="true"
        hide-default-footer
    >
      <!--Action -->
      <template v-slot:item.volume="{ item }">
          {{Intl.NumberFormat().format(item.volume)}}
      </template>
      <template v-slot:item.buy_min_price="{ item }">
          {{Intl.NumberFormat().format(item.buy_min_price)}}
      </template>
      <template v-slot:item.pro_type="{ item }">
        {{promotionTypeText(item.pro_type)}}
      </template>
      <template v-slot:item.pro_code="{ item }">
        <div class="info--text">
          {{item.pro_code}}
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <div>
          {{ moment(item.created_at).format("DD-MM-YYYY") }}
        </div>
      </template>
      <template v-slot:item.use_for="{ item }">
        <div>
          {{useFor(item.use_for)}}
        </div>
      </template>
      <template v-slot:item.expired_date="{ item }">
        <div class="error--text">
          {{ moment(item.expired_date).format("DD-MM-YYYY") }}
        </div>
      </template>
    </v-data-table>
    <br/>
    <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchData()"
    ></Pagination>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
          >Cancel
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm()"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>

  </div>
</template>

<script>
import manage from "@/mixins/platform/customer_use_promotion";
import Pagination from "@/plugins/pagination/pagination";

export default {
  mixins: [manage],
  components: {
    Pagination,
  },
};
</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../../public/scss/main.scss";
</style>