//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            expired_date: '',
            // expired_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            //     .toISOString()
            //     .substr(0, 10),
            expired_menu: false,
            promotion: {},
            limit_min_price: 1,
            limit_use: 0,
            promotions: [],

            selectedProType: 'money',
            promotionType: [
                {
                    id: 'water',
                    name: 'ແຖມຕຸກນ້ຳ'
                },
                {
                    id: 'money',
                    name: 'ຈຳນວນເງິນ'
                },
                {
                    id: 'percent',
                    name: 'ສ່ວນຫຼຸດເປີເຊັນ'
                }
            ],
            selectedEditRole: '',
            selectedTruck: '',
            dataID: '',
            server_errors: {
                pro_code: '', description: '', name: '',expired_date:''
            },
            news: {},

            image: "",
            imageUrl: "",
            selectedUseType: '',
            useType: [
                {
                    id: 'buy_water',
                    name: 'ຊື້ນ້ຳດື່ມ'
                },
                {
                    id: 'buy_product',
                    name: 'ຊື້ສິນຄ້າ'
                },
            ],

            //Pagination
            offset: 15,
            pagination: {},
            per_page: 12,
            headers: [
                {
                    text: "ລູກຄ້າ", align: "start", sortable: false, value: "name", width: "150px"
                },
                {
                    text: "ລະຫັດສ່ວນຫຼຸດ", align: "center", sortable: false, value: "pro_code", width: "150px"
                },
                {
                    text: "ຊື່ໂປຣໂມຊັ້ນ", align: "start", sortable: false, value: "proName", width: "150px"
                },
                {text: "ປະເພດໂປຣ", value: "pro_type", width: "150px"},
                {text: "ສ່ວນຫຼຸດ", value: "volume", width: "150px"},
                {text: "ຊື້ຂັ້ນຕຳ່", value: "buy_min_price", width: "150px"},
                {text: "ໃຊ້ຊື້", value: "use_for", width: "150px"},
                {text: "ວັນທີໃຊ້", value: "created_at", width: "150px"},
                {text: "", value: "actions", sortable: false},
            ],
            toast: {
                value: true, color: 'success', msg: 'Success'
            },
            toast_error: {
                value: true, color: 'error', msg: 'Something when wrong!'
            }
        }
    },
    methods: {
        fetchData() {
            this.TableLoading = true;
            this.$admin.get('user/use/promotion',
                {
                    params: {
                        page: this.pagination.current_page,
                        per_page: this.per_page,
                        use_for:this.selectedUseType,
                    }
                }).then(res => {
                this.promotions = res.data.data.data;
                this.pagination = res.data.data;
                this.TableLoading = false;
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });
        },
        Save() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },
        Submit() {
            const data = {
                pro_code: this.promotion.pro_code,
                name: this.promotion.name,
                description: this.promotion.description,
                limit_min_price: this.limit_min_price,
                buy_min_price: this.promotion.buy_min_price,
                limit_use: this.limit_use,
                amount_use: this.promotion.amount_use,
                pro_type: this.selectedProType,
                volume: this.promotion.volume,
                expired_date: this.expired_date,
            };

            this.$admin.post('/promotion/add', data)
                .then(res => {
                    if (res.status == 200) {
                        setTimeout(() => {
                            this.loading = false;
                            this.promotion = {},
                                this.$router.go(-1);
                        }, 300);
                    }
                }).catch(error => {
                this.loading = false;
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                    this.$store.commit("Toast_State", this.toast_error);
                }
            });
        },

        updatePromotion(id) {
            this.$router.push({name: 'UpdatePromotion', params: {id: id}});
        },

        createPromotion() {
            this.$router.push({name: 'CreateCustomerPromotion'});
        },
        Close() {
            this.$router.go(-1);
        },
        deleteItem(id) {
            this.dataID = id;
            this.$store.commit("modalDelete_State", true);
        },
        deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('user/promotion/delete/' + this.dataID).then(res => {
                if (res.status == 200) {
                    setTimeout(() => {
                        this.fetchData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 300);
                }
            }).catch(() => {
                this.$store.commit("modalDelete_State", false);
                this.loading = false;
            })
        },
        useFor(value){
            if(value == 'buy_water') return 'ສັ່ງຊື້ນ້ຳ';
            else if(value == 'buy_product') return 'ຊື້ສິນຄ້າ';
        },
        promotionTypeText(txt) {
            if (txt == 'money') return 'ຈຳນວນເງິນ';
            else if (txt == 'percent') return 'ເປິເຊັນ';
            else if (txt == 'water') return 'ຈຳນວນຕຸກນ້ຳ';
            else return;
        },
        reset() {
            this.$refs.form.reset();
        },
    },
    watch:{
        selectedUseType:function (){
            this.pagination.current_page = '';
            this.fetchData();
        },
        'promotion.pro_code': function () {
            this.server_errors.pro_code = '';
        },
        'promotion.name': function () {
            this.server_errors.name = '';
        },
        'promotion.expired_date': function () {
            this.server_errors.expired_date = '';
        },
        'promotion.volume': function () {
            this.server_errors.volume = '';
        },
        'promotion.buy_min_price': function () {
            this.server_errors.buy_min_price = '';
        },
    },
    created() {
        this.fetchData();
    },
}
